<template lang="pug">
  .c-carousel-mv(
    :class=`{
      'c-carousel-mv--auto-play': settings.autoplay,
      'c-carousel-mv--disabled': settings.disabled,
      'c-carousel-mv--fade': settings.fade && !settings.disabled,
      'c-carousel-mv--rtl': settings.rtl,
    }`
  )
    .c-carousel-mv__list(ref="list")
      .c-carousel-mv__track(
        ref="track"
        :style="{ \
          transform: `translate(${translateX + marginX}px)`, \
          transition: `transform ${settings.timing} ${transitionDelay}ms`, \
        }"
        @mouseover="handleMouseOver('track')"
        @mouseout="handleMouseOut('track')"
      )
        .c-carousel-mv__slides.c-carousel-mv__slides--cloned(
          v-if="clonedSlides"
          ref="slidesClonedBefore"
        )
          slot
        .c-carousel-mv__slides.c-carousel-mv__slides--regular(ref="slides")
          slot

        .c-carousel-mv__slides.c-carousel-mv__slides--cloned(
          v-if="clonedSlides"
          ref="slidesClonedAfter"
        )
          slot

    slot(v-if="!settings.disabled && settings.navButtons" name="navigation")
      bl-carousel-nav-mv(
        v-if="isNavigationRequired()"
        :next-label="navigationNextLabel"
        :prev-label="navigationPrevLabel"
        @navigationclick="handleNavigation"
      )
    slot(v-if="settings.dots && !settings.disabled" name="pagination")
      bl-carousel-pagination-mv(@paginationclick="handlePagination")

</template>

<script>
import BlCarouselNavMv from './components/BlCarouselNavigationMv';
import BlCarouselPaginationMv from './components/BlCarouselPaginationMv';

import handlers from './mixins/handlers';
import helpers from './mixins/helpers';
import preparations from './mixins/preparations';
import props from './mixins/props';
import watchers from './mixins/watchers';

export default {
  name: 'BlCarouselMv',
  status: 'prototype',
  release: '0.1.0',

  components: {
    BlCarouselNavMv,
    BlCarouselPaginationMv,
  },

  mixins: [handlers, helpers, preparations, props, watchers],

  data() {
    return {
      slides: [],
      slidesClonedBefore: [],
      slidesClonedAfter: [],
      autoplayInterval: null,
      autoplayTimeout: null,
      pauseAutoPlay: false,
      autoplayStart: null,
      autoplayRemaining: null,
      // autoplayStatus: false,
      // autoplayTimeout: null,
      currentSlide: null,
      mouseDown: false,
      dragStartX: 0,
      dragStartY: 0,
      dragDistance: 0,
      swipeDistance: 50,
      translateX: 0,
      transitionDelay: 0,
      widthWindow: 0,
      widthContainer: 0,
      widthSlide: 0,
      settings: {},
    };
  },

  // use `provide` to avoid `Slide` being nested with other components
  provide() {
    return {
      carousel: this,
    };
  },

  computed: {
    canGoToPrev() {
      return this.settings.infinite || this.currentSlide > 0;
    },

    canGoToNext() {
      return this.settings.infinite || this.currentSlide < this.pageCount - 1;
    },

    clonedSlides() {
      return !this.settings.disabled && !this.settings.fade && this.settings.infinite;
    },

    breakpoints() {
      return !this.initialSettings.responsive ? [] : this.initialSettings.responsive.map(item => item.breakpoint);
    },

    currentBreakpoint() {
      const breakpoints = this.breakpoints.map(item => item).reverse();
      return this.initialSettings.mobileFirst
        ? breakpoints.find(item => item < this.widthWindow) || 0
        : breakpoints.find(item => item > this.widthWindow) || null;
    },

    allSlides() {
      return this.clonedSlides ? [...this.slidesClonedBefore, ...this.slides, ...this.slidesClonedAfter] : this.slides;
    },

    slidesCount() {
      return this.slides.length;
    },

    allSlidesCount() {
      return this.allSlides.length;
    },

    marginX() {
      let marginX = this.clonedSlides ? this.slidesCount * this.widthSlide : 0;

      // Center mode margin
      if (this.settings.centerMode) {
        marginX -=
          (Math.floor(this.settings.slidesToShow / 2) - +(this.settings.slidesToShow % 2 === 0)) * this.widthSlide;
      }

      return this.settings.rtl ? marginX : -1 * marginX;
    },

    /**
     * Calculate the number of pages of slides
     * @return {Number} Number of pages
     */
    pageCount() {
      const scrollItem = this.slidesCount - this.slidesToShow;
      return this.scrollPerPage ? Math.ceil(scrollItem / this.slidesToScroll) + 1 : scrollItem + 1;
    },
  },

  created() {
    // Read settings from options object
    if (this.options) {
      Object.keys(this.options).forEach(key => {
        this.initialSettings[key] = this.options[key];
      });
    }

    // Sort breakpoints
    if (this.initialSettings.responsive) {
      this.initialSettings.responsive.sort(this.compare);
    }

    // Set first load settings
    Object.assign(this.settings, this.initialSettings);
  },

  mounted() {
    // Windows resize listener
    window.addEventListener('resize', this.getWidth);

    // Mouse and touch events
    if ('ontouchstart' in window) {
      this.$refs.track.addEventListener('touchstart', this.handleMouseDown);
      this.$refs.track.addEventListener('touchend', this.handleMouseUp);
      this.$refs.track.addEventListener('touchmove', this.handleMouseMove);
    } else {
      this.$refs.track.addEventListener('mousedown', this.handleMouseDown);
      this.$refs.track.addEventListener('mouseup', this.handleMouseUp);
      this.$refs.track.addEventListener('mousemove', this.handleMouseMove);
    }

    // Init
    this.reload();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);

    this.$refs.track.removeEventListener('ontouchstart' in window ? 'touchstart' : 'mousedown', this.handleMouseDown);
    this.$refs.track.removeEventListener('ontouchstart' in window ? 'touchend' : 'mouseup', this.handleMouseUp);
    this.$refs.track.removeEventListener('ontouchstart' in window ? 'touchmove' : 'mousemove', this.handleMouseMove);

    this.disableAutoPlay();
  },

  methods: {
    // Reload carousel
    reload() {
      this.getWidth();
      this.prepareSettings();
      this.prepareSlides();
      this.prepareCarousel();
      this.toggleFade();
    },
    toggleFade() {
      const enabled = !this.settings.disabled && this.settings.fade;

      for (let i = 0; i < this.slidesCount; i += 1) {
        this.slides[i].style.transition = enabled ? `opacity ${this.settings.timing} ${this.settings.speed}ms` : 'none';
        this.slides[i].style.transform = enabled ? `translate(-${i * this.widthSlide}px)` : 'none';
      }
    },

    toggleAutoPlay() {
      const enabled = !this.settings.disabled && this.settings.autoplay;

      if (!this.autoplayInterval && enabled) {
        this.autoplayInterval = setInterval(() => {
          if (!document.hidden) {
            if (!this.canGoToNext) {
              this.disableAutoPlay();
            } else {
              this.goToNext();
            }
          }
        }, this.settings.autoplaySpeed);
      } else {
        this.disableAutoPlay();
      }
    },

    restartAutoPlay() {
      this.disableAutoPlay();
      this.toggleAutoPlay();
    },

    disableAutoPlay() {
      clearInterval(this.autoplayInterval);
      this.autoplayInterval = null;
    },

    clearAutoPlayPause() {
      clearTimeout(this.autoplayTimeout);
      this.autoplayRemaining = null;
    },

    disableScroll() {
      document.ontouchmove = e => e.preventDefault();
    },

    enableScroll() {
      document.ontouchmove = () => true;
    },

    // Return current slide index
    getCurrentSlide() {
      return this.currentSlide;
    },

    // Return current breakpoint
    getCurrentBreakpoint() {
      return this.currentBreakpoint;
    },

    // Return settings for current breakpoint
    getCurrentSettings() {
      return this.settings;
    },

    // Return initial settings
    getInitialSettings() {
      return this.initialSettings;
    },

    // @return {Boolean} Is navigation required?
    isNavigationRequired() {
      return this.allSlides.length > this.settings.slidesToShow;
    },

    // Go to next slide
    goToNext() {
      if (this.canGoToNext) {
        this.goTo(this.currentSlide + 1);
      }
    },

    // Go to previous slide
    goToPrev() {
      if (this.canGoToPrev) {
        this.goTo(this.currentSlide - 1);
      }
    },

    // Go to slide
    goTo(n, transition = true, asNav = false) {
      // Break goTo() if disabled is active
      if (this.settings.disabled) {
        return;
      }

      if (!asNav) {
        this.settings.asNavFor.forEach(carousel => {
          if (carousel) {
            carousel.goTo(n, transition, true);
          }
        });
      }

      let realNextSlide = n;

      if (transition) {
        if (this.settings.infinite && n < 0) {
          realNextSlide = this.pageCount - 1;
        } else if (n >= this.pageCount) {
          realNextSlide = 0;
        }

        this.$emit('beforeChange', { currentSlide: this.currentSlide, nextSlide: realNextSlide });

        this.currentSlide = realNextSlide;

        if (n !== realNextSlide) {
          setTimeout(() => {
            this.goTo(realNextSlide, false);
          }, this.settings.speed);
        }
      }

      const translateX = !this.settings.fade ? n * this.widthSlide * this.settings.slidesToScroll : 0;
      this.transitionDelay = transition ? this.speed : 0;
      this.translateX = this.settings.rtl ? translateX : -1 * translateX;
    },

    handleNavigation(direction) {
      this.advancePage(direction);
      this.restartAutoPlay();
      this.$emit('navigation-click', direction);
    },

    handlePagination(index) {
      this.goTo(index);
      this.restartAutoPlay();
    },

    advancePage(direction) {
      if (direction && direction === 'backward' && this.canGoToPrev) {
        this.goToPrev();
      } else if ((!direction || (direction && direction !== 'backward')) && this.canGoToNext) {
        this.goToNext();
      }
    },
  },
};
</script>

<style src="./BlCarouselMv.style.scss" lang="scss"></style>
