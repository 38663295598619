<template lang="pug">
  .c-carousel-mv__pagination
    .c-carousel-mv__dots(role="tablist")
      .c-carousel-mv__dot(
        v-for="(page, index) in paginationCount"
        :key="`${page}_${index}`"
        :title="getDotTitle(index)"
        :value="getDotTitle(index)"
        :aria-label="getDotTitle(index)"
        :aria-selected="isCurrentDot(index) ? 'true' : 'false'"
        :class="{ '-active': isCurrentDot(index) }"
        aria-hidden="false"
        role="tab"
        @click="goToPage(index)"
        @mouseover="carousel.handleMouseOver('dot')"
        @mouseout="carousel.handleMouseOut('dot')"
      )
        button(type="button")
</template>

<script>
export default {
  name: 'BlCarouselPaginationMv',
  inject: ['carousel'],
  computed: {
    paginationCount() {
      return this.carousel && this.carousel.slidesToScroll
        ? this.carousel.pageCount
        : this.carousel.slidesCount || 0;
    },
  },
  methods: {
    /**
     * Change page by index
     * @param {number} index
     * return {void}
     */
    goToPage(index) {
      /**
       * @event paginationclick
       * @type {number}
       */
      this.$emit('paginationclick', index);
    },
    /**
     * Check on current dot
     * @param {number} index - dot index
     * @return {boolean}
     */
    isCurrentDot(index) {
      return index === this.carousel.currentSlide;
    },
    /**
     * Generate dot title
     * @param {number} index - dot index
     * @return {string}
     */
    getDotTitle(index) {
      return this.carousel.$children[index].title
        ? this.carousel.$children[index].title
        : `Item ${index}`;
    },
  },
};
</script>
<style src="./BlCarouselPaginationMv.style.scss" lang="scss"></style>
