<template lang="pug">
  component(:is="rootTag" :class="listClasses")
    div(:class="headingClasses")
      h2.c-product-list-header.u-txt--medium.u-txt--bold {{ title }}
      a(v-if="moreUrl" :href="moreUrl" rel="nofollow")
        bl-button-av(size="small" @click="handleSeeAllClick") Lihat Semua
    .c-product-details-listing__body(:class="bodyClasses")
      slot
</template>

<script>
import { BlButtonAv, BlPanelMv } from '@bukalapak/bazaar-dweb-v0';

export default {
  name: 'BlProductList',

  components: {
    BlButtonAv,
    BlPanelMv
  },

  props: {
    moreUrl: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      default: '',
    },
  },

  computed: {
    rootTag() {
      return this.variant === 'panel' ? 'bl-panel-mv' : 'div';
    },

    listClasses() {
      return {
        'c-product-details-listing': this.variant === 'product-detail',
      };
    },
    headingClasses() {
      return {
        'c-product-details-listing__heading': this.variant === 'product-detail',
        'u-mrgn-bottom--2': !this.variant,
        'u-pad--4': this.variant === 'panel',
      };
    },
    bodyClasses() {
      return {
        'u-pad-h--4': this.variant === 'panel',
      };
    },
  },

  methods: {
    handleSeeAllClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style src="./BlProductListMv.style.scss" lang="scss"></style>
