<template lang="pug">
component.c-product-card(:is='tag')
  bl-card-head-mv.c-product-card__image.u-bg--white
    .c-product-card__badges--top
      .c-label.c-label--yellow(v-if='hasWholesale') Grosir
      bl-badge-av(v-if='hasDeal', color='pink', size='large') {{ product.deal.percentage }}%
    bl-media-mv(
      :aspect-ratio='1',
      :transition='false',
      :alt='product.name',
      :src='imgUrl',
      :lazy-src='imageLazy("medium")',
      contain
    )
      template(slot='placeholder')
        .c-media__placeholder__content
          bl-spinner-av(size='medium')
    .c-product-card__badges--bottom
      .c-badges__bukamall(v-if='isBrandSeller')
        img(:src='`https://s1.bukalapak.com/pavilion/1586920121610/original/bukamall-logo.png`', alt='BukaMall')
      template(v-else-if='isSuperSeller && isFinishedFetchBadgeToggle')
        .c-badges__new-super-seller(v-if='isSuperSellerNewBadgeActive')
        .c-badges__super-seller(v-else) Super Seller
      .c-label.c-product-card__label__second(v-if='isSecondProduct') Bekas

    .c-product-card__overlay(v-if='showOverlay')
      a.c-product-card__overlay__link(:href='productUrl', @click='handleClick')
      .c-product-card__overlay__content(v-if='product.store')
        a.c-product-card__store-name(:href='product.store.url') {{ product.store.name }}
        p.c-product-card__store-location {{ product.store.address.city }}
        .c-product-card__store-level.u-mrgn-top--1
          img.c-product-card__store-level__icon(
            v-if='product.store.level.image_url',
            :src='product.store.level.image_url',
            :title='product.store.level.name',
            width='24',
            height='24'
          )
          a.c-product-card__store-level__link(:href='feedbackUrl', target='_blank', rel='noopener noreferer')
            | {{ storeReview.percentage }}% ({{ storeReview.total }} feedback)
        hr.u-hr--bleed.u-mrgn-top--2.u-mrgn-bottom--2(v-if='isBrandSeller || isSuperSeller')
        .c-product-card__store-brand(v-if='isBrandSeller')
          img(:src='`https://s1.bukalapak.com/pavilion/1586920121610/original/bukamall-logo.png`', alt='BukaMall')
        template(v-else-if='isSuperSeller && isFinishedFetchBadgeToggle')
          .c-badges__new-super-seller(v-if='isSuperSellerNewBadgeActive')
          .c-badges__super-seller(v-else) Super Seller

  bl-card-body-mv.c-product-card__content
    .c-product-card__rating(v-if='product.rating.average_rate && showRating')
      bl-rating-mv(:value='ratingVal', :max-ratings='5', :title='product.rating.average_rate', variant='star')
      a.u-mrgn-left--1.u-mrgn--0(:href='reviewUrl', target='_blank', rel='noopener noreferer') ({{ product.rating.user_count }} ulasan)
    h3.c-product-card__name
      a(:href='productUrl', :title='product.name', @click='handleClick') {{ product.name }}
    .c-product-card__price.-deal(v-if='hasDeal')
      span.original_price {{ price("original") }}
      span.discount_price {{ price("discount_price") }}
    .c-product-card__price(v-else)
      span.original_price {{ price() }}
    slot(name='content-footer')
</template>

<script>
import BlBadgeAv from '@bukalapak/bazaar-dweb-v0/dist/components/BlBadgeAv';
import BlIconAv from '@bukalapak/bazaar-dweb-v0/dist/components/BlIconAv';
import BlSpinnerAv from '@bukalapak/bazaar-dweb-v0/dist/components/BlSpinnerAv';
import BlRatingMv from '@bukalapak/bazaar-dweb-v0/dist/components/BlRatingMv';
import BlCardHeadMv from '@bukalapak/bazaar-dweb-v0/dist/components/BlCardHeadMv';
import BlCardBodyMv from '@bukalapak/bazaar-dweb-v0/dist/components/BlCardBodyMv';

import { appendParams } from 'shared_js/helpers/url-helper';

import BlMediaMv from '../BlMediaMv';
import imageHelper from '../../mixins/image-helper';

export default {
  name: 'BlProductCard',
  status: 'prototype',
  release: '0.1.0',
  components: {
    BlBadgeAv,
    BlIconAv,
    BlSpinnerAv,
    BlRatingMv,
    BlCardHeadMv,
    BlCardBodyMv,
    BlMediaMv,
  },

  mixins: [imageHelper],

  props: {
    /**
     * The html element used for the button.
     * `button, a`
     */
    tag: {
      type: String,
      default: 'div',
      validator: (value) => value.match(/(div|ul)/),
    },

    product: {
      type: Object,
      default() {
        return null;
      },
    },

    productUrlParams: {
      type: Object,
      default: () => ({}),
    },

    showRating: {
      type: Boolean,
      default: true,
    },

    showOverlay: {
      type: Boolean,
      default: true,
    },

    isFinishedFetchBadgeToggle: {
      type: Boolean,
      default: false,
    },

    isSuperSellerNewBadgeActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasDeal() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const { deal } = this.product;
      return deal && deal.discount_price && today >= new Date(deal.applied_date) && today < new Date(deal.expired_date);
    },

    hasWholesale() {
      return this.product.wholesales && this.product.wholesales.length;
    },

    imgUrl() {
      const imgBig = this.product.images.large_urls[0];
      if (imgBig) {
        const arr = imgBig.split('/');
        arr[5] = 'medium';
        return arr.join('/');
      }

      return '';
    },

    isBrandSeller() {
      return this.product.store && this.product.store.brand_seller;
    },

    isSuperSeller() {
      return this.product.store && this.product.store.premium_top_seller;
    },

    isSecondProduct() {
      return this.product.condition && this.product.condition.toLowerCase() === 'bekas';
    },

    ratingVal() {
      return this.product.rating.average_rate * 20;
    },

    feedbackUrl() {
      return `${this.product.store.url}/feedback?feedback_as=as_seller&filter_by=all`;
    },

    reviewUrl() {
      return this.product.url ? this.product.url.replace('/p/', '/reviews/') : '';
    },

    productUrl() {
      return appendParams(this.product.url, this.productUrlParams);
    },

    storeReview() {
      let percentage = 0;
      let total = 0;

      if (this.product.store && this.product.store.reviews) {
        const { positive } = this.product.store.reviews;
        total = positive + this.product.store.reviews.negative;

        if (positive > 0) {
          percentage = Math.floor((positive / total) * 100);
        }
      }

      return {
        percentage,
        total,
      };
    },
  },

  methods: {
    money(val) {
      const str = `${parseInt(val, 10)}`; // convert to string
      const replaceStr = str.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      const delimited = `${val < 0 ? '-' : ''}${replaceStr}`;
      if (delimited.charAt(0) === '-') {
        return delimited.replace(/^-/g, '-Rp');
      }
      return `Rp${delimited}`;
    },

    price(type) {
      if (type === 'discount') {
        return this.money(this.product.deal.original_price);
      }
      if (type === 'original') {
        return this.money(this.product.deal.original_price);
      }

      return this.money(this.product.price);
    },

    handleClick(e) {
      if (global.pdpEventBus) {
        e.preventDefault();
        global.pdpEventBus.publish('changeProduct', {
          product: {
            id: this.product.id,
            name: this.product.name,
            url: this.product.url,
            store: {
              id: this.product.store.id,
              name: this.product.store.name,
            },
          },
        });
      }
      this.$emit('click', this.product);
    },
  },
};
</script>

<style src="./BlProductCardMv.style.scss" lang="scss" scoped></style>
