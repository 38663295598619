/**
 * Carousel preparation methods
 */
const mixin = {
  methods: {
    /**
     * Prepare settings object
     */
    prepareSettings() {
      if (!this.initialSettings.responsive) {
        this.toggleFade();
        this.toggleAutoPlay();
        return;
      }

      const newSettings = Object.assign({}, this.initialSettings);
      delete newSettings.responsive;

      this.initialSettings.responsive.forEach(option => {
        if (
          this.initialSettings.mobileFirst
            ? option.breakpoint < this.widthWindow
            : option.breakpoint > this.widthWindow
        ) {
          Object.keys(option.settings).forEach(key => {
            newSettings[key] = option.settings[key];
          });
        }
      });

      this.settings = Object.assign({}, newSettings);
    },

    /**
     * Prepare slides classes and styles
     */
    prepareSlides() {
      this.slides = this.htmlCollectionToArray(this.$refs.slides.children);

      // Probably timeout needed
      if (this.clonedSlides) {
        this.slidesClonedBefore = this.htmlCollectionToArray(
          this.$refs.slidesClonedBefore.children,
        );
        this.slidesClonedAfter = this.htmlCollectionToArray(this.$refs.slidesClonedAfter.children);
      }

      this.allSlides.forEach(slide => {
        slide.classList.add('c-carousel-mv__slide');
      });
    },

    /**
     *  Prepare slides active/current classes
     */
    prepareSlidesClasses() {
      if (this.currentSlide === null) {
        return;
      }

      // Remove active & current classes
      for (let i = 0; i < this.slidesCount; i += 1) {
        this.slides[i].classList.remove('c-carousel-mv__slide--active');
        this.slides[i].classList.remove('c-carousel-mv__slide--current');
      }

      // Add active & current class for current slide
      if (this.slides[this.currentSlide]) {
        this.slides[this.currentSlide].classList.add('c-carousel-mv__slide--active');
      }

      let start = this.clonedSlides ? this.slidesCount + this.currentSlide : this.currentSlide;

      if (this.centerMode) {
        start -=
          Math.floor(this.settings.slidesToShow / 2) - +(this.settings.slidesToShow % 2 === 0);
      }

      // To account for the combination of infinite = false and centerMode = true, ensure we don't overrun the bounds of the slide count.
      for (
        let i = Math.max(start, 0);
        i < Math.min(start + this.settings.slidesToShow, this.slidesCount);
        i += 1
      ) {
        this.allSlides[i].classList.add('c-carousel-mv__slide--current');
      }
    },

    /**
     * Prepare carousel styles
     */
    prepareCarousel() {
      this.widthSlide = !this.settings.disabled
        ? this.widthContainer / this.settings.slidesToShow
        : 'auto';

      // Actions on document resize
      for (let i = 0; i < this.allSlidesCount; i += 1) {
        this.allSlides[i].style.width = `${this.widthSlide}px`;
      }

      // Prepare track
      if (this.settings.disabled) {
        this.translateX = 0;
      } else {
        if (this.currentSlide === null && this.slidesCount) {
          this.currentSlide = this.settings.initialSlide;
        }

        this.goTo(this.currentSlide, false, false);
      }
    },
  },
};

export default mixin;
