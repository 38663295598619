// image-helper.js file

export default {
  methods: {
    /**
     * imageUrl - replace given image's size to preferred size
     *
     * @param {string} url - Image url with expected format:
     *    http:// ... /bucket / ... / id / style / image_name
     *    expected style's values are [original, medium larg, small, thumb)]
     * @param {string} size - Any size in string that is supported by Imagery
     *
     * @returns {string} Replaced url with preferred size
     */
    imageUrl(url, size) {
      const imagePathRegex = /\/(original|large|medium|small|thumb)\//g;
      return url.replace(imagePathRegex, `/${size}/`);
    },

    imageLazy(size) {
      return global.assetUrl(`images/image-placeholder--${size}.png`);
    },
  },
};
