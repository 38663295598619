<template lang="pug">
  .c-carousel-mv__slide(tabindex="-1" role="tabpanel")
    slot
</template>

<script>
export default {
  name: 'BlCarouselSlideMv',
  data() {
    return {
      width: null,
    };
  },
  inject: ['carousel'],
};
</script>
