<template lang="pug">
  bl-product-list-mv(
    :title="title"
    :moreUrl="moreUrl"
    :variant="variant"
    @click="handleSeeAllClick")
    bl-carousel-mv(
      ref="carousel"
      :slides-to-show="contentWindow"
      :slides-to-scroll="2"
      :infinite="false"
      :dots="false"
      @navigation-click="navigationClick"
    )
      bl-carousel-slide-mv(v-for="product in products" :key="product.id")
        bl-product-card-mv(
          :product="product"
          :product-url-params="augmentedProductUrlParams(product.source)"
          @click="handleProductClick"
          :is-finished-fetch-badge-toggle="isFinishedFetchBadgeToggle"
          :is-super-seller-new-badge-active="isSuperSellerNewBadgeActive"
        )
</template>

<script>
import { BlCarouselMv, BlCarouselSlideMv } from './components/BlCarouselMv';
import BlMediaMv from './components/BlMediaMv';
import BlProductCardMv from './components/BlProductCardMv';
import BlProductListMv from './components/BlProductListMv';
import getSuperSellerBadgePromise from 'shared_js/helpers/super-seller-badge-promise';

export default {
  name: 'ProductListElysium',

  components: {
    BlCarouselMv,
    BlCarouselSlideMv,
    BlMediaMv,
    BlProductCardMv,
    BlProductListMv,
  },

  props: {
    handleNavigation: {
      type: Function,
      default: null,
    },

    moreUrl: {
      type: String,
      default: '',
    },

    products: {
      type: Array,
      required: true,
    },

    augmentedProductUrlParams: {
      type: Function,
      default: () => ({}),
    },

    title: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      default: '',
    },
    
    contentWindow: {
      type: Number,
      default: 6,
    },
  },

  data() {
    return {
      isSuperSellerNewBadgeActive: false,
      isFinishedFetchBadgeToggle: false,
    };
  },

  mounted() {
    this.getNewSuperSellerBadgeStatus();
  },

  methods: {
    handleProductClick(product) {
      this.$emit('productClick', product);
    },

    handleSeeAllClick(e) {
      this.$emit('seeAllClick', e);
    },

    navigationClick(direction) {
      this.handleNavigation(this.$refs.carousel.currentSlide);
      this.$emit('navigationClick', direction);
    },

    getNewSuperSellerBadgeStatus() {
      const promise = getSuperSellerBadgePromise();
      promise
        .then(res => {
          this.isSuperSellerNewBadgeActive = res.data[0] ? res.data[0].active : false;
        })
        .finally(() => {
          this.isFinishedFetchBadgeToggle = true;
        });
    },
  },
};
</script>
