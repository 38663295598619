import BlCarouselMv from './BlCarouselMv.vue'
import BlCarouselSlideMv from './components/BlCarouselSlideMv/BlCarouselSlideMv.vue'

const install = (Vue) => {
  Vue.component(BlCarouselMv.name, BlCarouselMv)
  Vue.component(BlCarouselSlideMv.name, BlCarouselSlideMv)
}

export default {
  install
}

export { BlCarouselMv, BlCarouselSlideMv }
