import api from '../api';

const NEW_SUPER_SELLER_BADGE_TOGGLE_ID = 'super-seller-rebranding-enabled';

function getSuperSellerBadgePromise() {
  if (!window.superSellerBadgeVersionPromise) {
    window.superSellerBadgeVersionPromise = api.retrieveTogglesStatus([NEW_SUPER_SELLER_BADGE_TOGGLE_ID]);
  }

  return window.superSellerBadgeVersionPromise;
}

export default getSuperSellerBadgePromise;
